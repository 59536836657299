import PropTypes from "prop-types";
// import { KeyOutlined } from "@ant-design/icons";
// material-ui
import React, { useRef, useState, useEffect } from "react";
import PasswordInput from "components/inputs/PasswordInput";
import LockResetIcon from "@mui/icons-material/LockReset";
import { MdOutlineFeedback } from "react-icons/md";
// import { Input } from 'antd';

import {
  ChangePassword,
  getAllNotification,
  REmoveFetchNotifiction,
  AddAllFeedbacks,
} from "store/action/index";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { dispatch } from "store/index";
import CircularProgress from "@mui/material/CircularProgress";
import { AES, enc } from "crypto-js";
import * as url from "../../../../../store/constant/Endpoints";
import { connect } from "react-redux";

// project-imports
import Avatar from "components/@extended/Avatar";
import MainCard from "components/MainCard";
import Transitions from "components/@extended/Transitions";
import { Button, Modal, Space, Input, Dropdown, Badge, Menu } from "antd";
import IconButton from "components/@extended/IconButton";
import USerViewNotifiationMdoal from "./notitificationdetailmofal";
// assets
import avatar1 from "assets/images/users/avatar-6.png";
import { Logout } from "iconsax-react";
import { BellOutlined } from "@ant-design/icons";

// tab panel wrapper
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
      sx={{ p: 1 }}
    >
      {value === index && children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const ProfilePage = () => {
  const theme = useTheme();

  const handleLogout = async () => {
    // Logout
    localStorage.clear();
    window.location.reload();
  };


  let permissionUser = localStorage.getItem("data");

  let decryptedData = null;
  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      console.log(error, "er");
    }
  }

  const loginDetails = JSON.parse(decryptedData);




  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [userAgainData, setUserAgain] = useState();
  const [notificationModalView, setNotiModalView] = useState(false);

  const handleChange = () => {
    setModalOpen(true);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };




  const handleCancelNoti = () => {
    setNotiModalView(false);
  };

  if (permissionUser) {
    try {
      // Code that may throw an exception
      decryptedData = AES.decrypt(
        permissionUser,
        url.encryptionSecret
      ).toString(enc.Utf8);
      // ...
    } catch (error) {
      localStorage.clear();
      window.location.reload();
    }
  }

    
  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <div style={{ display: "flex" }}>


        <ButtonBase
          sx={{
            p: 0.25,
            borderRadius: 1,
            outlineOffset: 2,
            backgroundColor:'#F9F5F0'
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Avatar
            style={{ padding: 4 }}
            alt="profile user"
            src={
              loginDetails?.image !== null && loginDetails?.image !== ""
                ? loginDetails?.image
                : avatar1
            }
          />
         
        </ButtonBase>
      </div>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 250,
                },
                borderRadius: 1.5,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard sx={{ border: "none" }} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar
                            style={{ padding: 4 }}
                            alt="profile user"
                            src={
                              loginDetails?.image !== null &&
                              loginDetails?.image !== ""
                                ? loginDetails?.image
                                : avatar1
                            }
                          />
                          <Stack>
                            <Typography variant="subtitle1">
                              Admin
                            </Typography>
                            <Typography variant="body2" color="secondary">
                              {loginDetails?.user?.email !== null &&
                              loginDetails?.user?.email !== ""
                                ? loginDetails?.user?.email
                                : "No Email"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>

                      <Grid item>
                        <Tooltip title="Logout">
                          <IconButton
                            size="large"
                            color="error"
                            sx={{ p: 1 }}
                            onClick={handleLogout}
                          >
                            <Logout variant="Bulk" />
                          </IconButton>
                        </Tooltip>
                      </Grid>

            
                    </Grid>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <USerViewNotifiationMdoal
        notificationModalView={notificationModalView}
        setNotiModalView={setNotiModalView}
        handleCancelNoti={handleCancelNoti}
        userAgainData={userAgainData}
      />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    notificationData: state?.Notificationgetall,
  };
};

export default connect(mapStateToProps)(ProfilePage);
