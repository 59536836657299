import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import { Navigate } from 'react-router-dom';

const Blogs = Loadable(
  lazy(() => import("pages/blogs/AllBlogs"))
);


const CreateBlogs = Loadable(
  lazy(() => import("pages/blogs/CreateBlog"))
);



const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/hospitals/all-hospitals" replace />,
        },
      ],
    },
    {
      path: "hospitals",
      element: <MainLayout />,
      children: [
        {
          path: "all-hospitals",
          element: <Blogs />,
        },
        {
          path: "create-hospitals",
          element: <CreateBlogs />,
        },
      ],
    },
  ].filter(Boolean),
};

export default MainRoutes;
