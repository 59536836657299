import { ALL_BLOGS_GET } from "../../constant/ActionType";

export default function GetAllBlogs(
  state = {
    allBlogsData: [],
  },
  action
) {
  switch (action.type) {
    case ALL_BLOGS_GET:
      return {
        ...state,
        allBlogsData: action?.data,
      };

    default:
  }

  return state;
}


