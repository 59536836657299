import blogs from "./allblogs";


const menuItems = {
  items: [

   blogs,
 
  ],
};

export default menuItems;
