// third-party
import { combineReducers } from "redux";

// project-imports
import menu from "./menu";
import GetAllBlogs from "./allBlogs/AllBlogs";



// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  GetAllBlogs,  
});

export default reducers;
