// type
import { MdHome } from "react-icons/md";
import { BsBuildingFillAdd } from "react-icons/bs";
import { TbRulerMeasure } from "react-icons/tb";
import { HiOutlineReceiptTax } from "react-icons/hi";
import { TbHomeCog } from "react-icons/tb";
import { MdOutlineBedroomParent } from "react-icons/md";
import { FiUsers } from "react-icons/fi";

// icons
const icons = {
  navigation: MdHome,
  flatType: TbHomeCog,
  amenities: BsBuildingFillAdd,
  flatSize: TbRulerMeasure,
  taxes: HiOutlineReceiptTax,
  flat: MdOutlineBedroomParent,
  color: MdHome,
  shadow: MdHome,
  clients: FiUsers,
};

// ==============================|| MENU ITEMS - roles ||============================== //

// const flat = permissionsAccessFunction('company') ? {
const blogs = true
  ? {
      id: "Hospitals",
      title: "Hospitals",
      icon: icons.amenities,
      type: "group",
      children: [
        {
              id: "all-Hospitals",
              title: "Hospitals",
              type: "item",
              url: "/hospitals/all-hospitals",
              icon: icons.amenities,
              breadcrumbs: true,
            },
      ].filter(Boolean),
    }
  : null;

export default blogs;
